var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "table",
      {
        class: ["dt-custom v-datatable-light no-borders", _vm.css.table],
        staticStyle: { "margin-bottom": "0" },
      },
      [
        _c("thead", { class: _vm.css.thead, style: _vm.theadStyle }, [
          _c(
            "tr",
            { class: _vm.css.theadTr, staticStyle: { "min-width": "100%" } },
            _vm._l(_vm.headers, function (item, columnIndex) {
              return _c(
                "th",
                {
                  key: item.label + _vm.tableDataHash,
                  class: _vm.headerItemClass(item, [
                    _vm.css.theadTh,
                    "header-column-" + columnIndex,
                  ]),
                  style: _vm.getColumnWidth(item),
                },
                [
                  !_vm.isFieldSpecial(item.name) && !item.customHeader
                    ? _c(
                        "div",
                        {
                          class: [
                            _vm.css.thWrapper,
                            "is-size-7 header-column-" + columnIndex,
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.orderBy(item.name)
                            },
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(item.label) + " "),
                          item.required
                            ? _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ])
                            : _vm._e(),
                          item.sortable
                            ? _c(
                                "div",
                                {
                                  class: _vm.arrowsWrapper(
                                    item.name,
                                    _vm.css.arrowsWrapper
                                  ),
                                },
                                [
                                  _vm.showOrderArrow(item, "desc")
                                    ? _c("div", { class: _vm.css.arrowUp })
                                    : _vm._e(),
                                  _vm.showOrderArrow(item, "asc")
                                    ? _c("div", { class: _vm.css.arrowDown })
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isFieldSpecial(item.name) && item.customHeader
                    ? _c(
                        "div",
                        {
                          class: [
                            _vm.css.thWrapper,
                            "header-column-" + columnIndex,
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.orderBy(item.name)
                            },
                          },
                        },
                        [
                          item.customHeader
                            ? _vm._t(_vm.customHeaderName(item), null, {
                                headerData: item,
                              })
                            : _vm._e(),
                          item.sortable
                            ? _c(
                                "div",
                                {
                                  class: _vm.arrowsWrapper(
                                    item.name,
                                    _vm.css.arrowsWrapper
                                  ),
                                },
                                [
                                  _vm.showOrderArrow(item, "desc")
                                    ? _c("div", { class: _vm.css.arrowUp })
                                    : _vm._e(),
                                  _vm.showOrderArrow(item, "asc")
                                    ? _c("div", { class: _vm.css.arrowDown })
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.isFieldSpecial(item.name) &&
                  _vm.extractArgs(item.name) === "checkboxes"
                    ? _c("div", { class: _vm.css.thWrapperCheckboxes }, [
                        _c("input", {
                          class: _vm.css.checkboxHeader,
                          attrs: { type: "checkbox", disabled: _vm.disabled },
                          domProps: { checked: _vm.checkedAll },
                          on: { click: _vm.checkAll },
                        }),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]),
        _c(
          "tbody",
          { class: _vm.css.tbody, style: _vm.tbodyStyle },
          [
            _vm.isLoading
              ? [
                  _c("tr", { class: _vm.css.tbodyTrSpinner }, [
                    _c(
                      "td",
                      {
                        class: _vm.css.tbodyTdSpinner,
                        attrs: { colspan: _vm.headers.length },
                      },
                      [_vm._t("spinner")],
                      2
                    ),
                  ]),
                ]
              : _vm.tableData.length
              ? [
                  _vm._l(_vm.tableData, function (item, index) {
                    return _c(
                      "tr",
                      {
                        key: item.id + _vm.tableDataHash,
                        class: [
                          _vm.css.tbodyTr,
                          "row-" + index,
                          item.isDeleted === 1 ? "is-deleted" : "",
                        ],
                        staticStyle: { "min-width": "100%" },
                      },
                      _vm._l(_vm.headers, function (key, columnIndex) {
                        return _c(
                          "td",
                          {
                            key: index + "-" + key.name + _vm.tableDataHash,
                            class: [_vm.css.tbodyTd, "column-" + columnIndex],
                            style: { width: "auto", minWidth: key.width },
                          },
                          [
                            _vm.isFieldSpecial(key.name) &&
                            _vm.extractArgs(key.name) === "actions"
                              ? _vm._t(_vm.extractActionID(key.name), null, {
                                  rowData: item,
                                  rowIndex: index,
                                })
                              : _vm._e(),
                            _vm.isFieldSpecial(key.name) &&
                            _vm.extractArgs(key.name) === "checkboxes"
                              ? _c("input", {
                                  class: _vm.css.checkbox,
                                  attrs: {
                                    disabled:
                                      item.isDeleted === 1 || _vm.disabled,
                                    type: "checkbox",
                                    "row-data": item,
                                    "row-index": index,
                                    "is-invalid-field":
                                      item["has" + key.name + "error"],
                                  },
                                  domProps: {
                                    checked:
                                      _vm.checkedAll || _vm.isCheckedItem(item),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checkItem(item, $event)
                                    },
                                  },
                                })
                              : _vm._e(),
                            key.customElement
                              ? _vm._t(_vm.customElementName(key), null, {
                                  rowData: item,
                                  rowIndex: index,
                                })
                              : key.format == "date" && key.isEditable
                              ? [
                                  !_vm.disabled
                                    ? _c("kendo-datepicker", {
                                        attrs: {
                                          disabled:
                                            item.isDeleted === 1 ||
                                            _vm.disabled,
                                          format: "yyyy-MM-dd",
                                          "is-invalid-field":
                                            item["has" + key.name + "error"],
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.preValidationRow(item)
                                          },
                                        },
                                        model: {
                                          value: item[key.name],
                                          callback: function ($$v) {
                                            _vm.$set(item, key.name, $$v)
                                          },
                                          expression: "item[key.name]",
                                        },
                                      })
                                    : _c("span", [
                                        _c("input", {
                                          attrs: {
                                            disabled: "true",
                                            "is-invalid-field":
                                              item["has" + key.name + "error"],
                                            type: "text",
                                          },
                                          domProps: {
                                            value:
                                              item[key.name] != undefined
                                                ? _vm
                                                    .moment(item[key.name])
                                                    .format("YYYY-MM-DD")
                                                : "",
                                          },
                                        }),
                                      ]),
                                ]
                              : key.format == "phone" && key.isEditable
                              ? [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item[key.name],
                                        expression: "item[key.name]",
                                      },
                                    ],
                                    attrs: {
                                      disabled:
                                        item.isDeleted === 1 || _vm.disabled,
                                      "is-invalid-field":
                                        item["has" + key.name + "error"],
                                    },
                                    domProps: { value: item[key.name] },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          item,
                                          key.name,
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              : key.format && key.isEditable
                              ? [
                                  _c("masked-input", {
                                    attrs: {
                                      disabled:
                                        item.isDeleted === 1 || _vm.disabled,
                                      maskType: key.format,
                                      "is-invalid-field":
                                        item["has" + key.name + "error"],
                                    },
                                    model: {
                                      value: item[key.name],
                                      callback: function ($$v) {
                                        _vm.$set(item, key.name, $$v)
                                      },
                                      expression: "item[key.name]",
                                    },
                                  }),
                                ]
                              : key.format && !key.isEditable
                              ? [_vm._v(_vm._s(key.format(item[key.name])))]
                              : key.isEditable
                              ? [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: item[key.name],
                                        expression: "item[key.name]",
                                      },
                                    ],
                                    attrs: {
                                      disabled:
                                        item.isDeleted === 1 || _vm.disabled,
                                      "is-invalid-field":
                                        item["has" + key.name + "error"],
                                    },
                                    domProps: { value: item[key.name] },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          item,
                                          key.name,
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              : [_vm._v(_vm._s(item[key.name]))],
                          ],
                          2
                        )
                      }),
                      0
                    )
                  }),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticStyle: { "text-align": "right" },
                        attrs: { colspan: _vm.headers.length + 1 },
                      },
                      [
                        !_vm.disableAddRow && !_vm.disabled
                          ? _c(
                              "a",
                              {
                                staticClass: "button is-light",
                                attrs: {
                                  disabled:
                                    _vm.tableData.length >= _vm.limit ||
                                    _vm.disableAddRow ||
                                    _vm.disabled,
                                },
                                on: { click: _vm.addRow },
                              },
                              [_vm._v("Add row")]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              : [
                  _c("tr", { class: _vm.css.notFoundTr }, [
                    _c(
                      "td",
                      {
                        class: _vm.css.notFoundTd,
                        attrs: { colspan: _vm.headers.length },
                      },
                      [_vm._v(" " + _vm._s(_vm.notFoundMessage) + " ")]
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticStyle: { "text-align": "right" },
                        attrs: { colspan: _vm.headers.length + 1 },
                      },
                      [
                        !_vm.disableAddRow
                          ? _c(
                              "a",
                              {
                                staticClass: "button is-light",
                                attrs: {
                                  disabled:
                                    _vm.tableData.length >= _vm.limit ||
                                    _vm.disableAddRow,
                                },
                                on: { click: _vm.addRow },
                              },
                              [_vm._v("Add row")]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ],
          ],
          2
        ),
        _vm.hasSlots
          ? _c("tfoot", { class: _vm.css.tfoot }, [
              _c("tr", { class: _vm.css.tfootTr }, [
                _c(
                  "th",
                  {
                    class: _vm.css.tfootTd,
                    attrs: { colspan: _vm.headers.length },
                  },
                  [
                    _c(
                      "div",
                      { class: _vm.css.footer },
                      [_vm._t("ItemsPerPage"), _vm._t("pagination")],
                      2
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }