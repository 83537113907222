var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.displayValue,
        expression: "displayValue",
      },
    ],
    attrs: { maskType: _vm.maskType },
    domProps: { value: _vm.displayValue },
    on: {
      blur: _vm.handleInputState,
      focus: _vm.handleInputState,
      keydown: _vm.handleInputState,
      input: function ($event) {
        if ($event.target.composing) {
          return
        }
        _vm.displayValue = $event.target.value
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }